/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getPZDocumentsList, getSaasDocumentsList, getSignatureQuestionnaires } from './extra'
import {
  CommonDocumentsSlice,
  PZDocumentsList,
  SaasDocumentsList,
  SignatureQuestionnairesResponse,
} from './types'

const initialState: CommonDocumentsSlice = {
  loadable: {},
  saasDocumentsList: [],
  pzDocumentsList: [],
  signatureQuestionnaires: [],
}

export const commonDocumentsSlice = createSlice({
  name: 'commonDocuments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSaasDocumentsList.pending, (state) => {
        state.loadable['saasDocumentsList'] = true
        state.saasDocumentsList = []
      })
      .addCase(getSaasDocumentsList.rejected, (state) => {
        state.loadable['saasDocumentsList'] = false
      })
      .addCase(
        getSaasDocumentsList.fulfilled,
        (state, { payload }: PayloadAction<SaasDocumentsList[]>) => {
          state.loadable['saasDocumentsList'] = false
          state.saasDocumentsList = payload
        }
      )
    builder
      .addCase(getPZDocumentsList.pending, (state) => {
        state.loadable['pzDocumentsList'] = true
        state.pzDocumentsList = []
      })
      .addCase(getPZDocumentsList.rejected, (state) => {
        state.loadable['pzDocumentsList'] = false
      })
      .addCase(
        getPZDocumentsList.fulfilled,
        (state, { payload }: PayloadAction<PZDocumentsList[]>) => {
          state.loadable['pzDocumentsList'] = false
          state.pzDocumentsList = payload
        }
      )
    builder
      .addCase(getSignatureQuestionnaires.pending, (state) => {
        state.loadable['signatureQuestionnaires'] = true
        state.pzDocumentsList = []
      })
      .addCase(getSignatureQuestionnaires.rejected, (state) => {
        state.loadable['signatureQuestionnaires'] = false
      })
      .addCase(
        getSignatureQuestionnaires.fulfilled,
        (state, { payload }: PayloadAction<SignatureQuestionnairesResponse[]>) => {
          state.loadable['signatureQuestionnaires'] = false
          state.signatureQuestionnaires = payload
        }
      )
  },
})

export default commonDocumentsSlice.reducer
