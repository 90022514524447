/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios'
import {
  IPassportDataUpdate,
  IPersonalDataUpdate,
  IRegistrationDataUpdate,
  IResidenceDataUpdate,
  IUserDetailsUpdateData,
} from 'features/loan/types'

import { ServiceBase } from './serviceBase'

const loanFields = [
  'loans.loan_date_create',
  // 'requests_count',
  'loans.loan_id',
  'loans.loan_number',
  'passport.first_name',
  'passport.last_name',
  'passport.middle_name',
  'users.login',
  'loans.loan_ask_sum',
  // 'loans.loan_sum',
  // 'passport.number',
  // 'passport.serial',
  // 'utm_source',
  'residence_address',
  'loan_statuses.status_name',
  'admin_username',
  'closed_count',
  // 'overdue_days',
  // 'loans.loan_permitted_sum',
  'agent_username',
  'agent_manager_username',
  'loans.product_id',
]

export class ProjectAgentLoansService extends ServiceBase {
  protected static LOAN_PATH = 'loans'

  protected static USERS_PATH = 'users'

  protected static COUNT_PAGE_REQUEST = 20

  public static getAgentLoans(iteration: number, filters: any): AxiosPromise {
    const data = JSON.stringify({
      fields: loanFields,
      ...filters,
    })

    return this.post(
      `${this.LOAN_PATH}/get?page_size=${this.COUNT_PAGE_REQUEST}&p=${iteration}`,
      data
    )
  }

  public static getAgentWaitingLoans(): AxiosPromise {
    return this.get(`agent_api/installment/users_no_loans`)
  }

  public static getAllAgentLoansCount(): AxiosPromise {
    return this.get(`agent_api/installment/request_loans_count`)
  }

  public static getRequiredDocsAgentLoansCount(): AxiosPromise {
    return this.get(`agent_api/installment/need_doc_loans_count`)
  }

  public static getCheckingAgentLoansCount(): AxiosPromise {
    return this.get(`agent_api/installment/on_check_loans_count`)
  }

  public static getAssignTheLoan(loanId: string): AxiosPromise {
    return this.patch(`agent_api/installment/set_status/agent_in_work/${loanId}`)
  }

  public static getCheckedAgentLoansCount(): AxiosPromise {
    return this.get(`agent_api/installment/approved_loans_count`)
  }

  public static getAtWorkAgentLoansCount(): AxiosPromise {
    return this.get(`agent_api/installment/agent_in_work_loans_count`)
  }

  public static getWorkingAgentLoansCount(): AxiosPromise {
    return this.get(`agent_api/installment/agent_in_work_loans_count`)
  }

  public static removeFromAgent(phone): AxiosPromise {
    const replacedPhone = phone.substring(1)
    return this.delete(`agent_api/${this.USERS_PATH}/remove_from_agent?phone=%2B${replacedPhone}`)
  }

  public static changeAgent(phone, agent_id): AxiosPromise {
    const data = JSON.stringify({
      agent_id,
    })
    const replacedPhone = phone.substring(1)
    return this.put(`agent_api/${this.USERS_PATH}/change_agent?phone=%2B${replacedPhone}`, data)
  }

  public static changeAgentAdmin(phone, admin_id): AxiosPromise {
    return this.post(
      `${this.USERS_PATH}/add_to_agent_admin?phone=${encodeURIComponent(
        phone
      )}&admin_id=${admin_id}`
    )
  }

  public static changeAgentLoan(phone, agent_id): AxiosPromise {
    const data = JSON.stringify({
      agent_id,
      phone,
    })
    return this.put(`${this.USERS_PATH}/change_agent`, data)
  }

  public static getPassportData(userId): AxiosPromise {
    return this.get(`${this.USERS_PATH}/passport/${userId}`)
  }

  public static getPersonalData(userId): AxiosPromise {
    return this.get(`${this.USERS_PATH}/personal-data/${userId}`)
  }

  public static addUser(phone): AxiosPromise {
    return this.post(`agent_api/users/add?phone=${encodeURIComponent(phone)}`)
  }

  public static loanAgentAccept({ id, comment }): AxiosPromise {
    return this.post(`agent_api/installment/accept?loan_id=${id}`, { comment })
  }

  public static getAgentUsers(id): AxiosPromise {
    return this.get(`agent_api/installment/agent_users?user_id=${id}`)
  }

  public static refuseLoan({
    id,
    comment,
    byClient,
    notice,
    ban_user,
    reject_code_id,
  }): AxiosPromise {
    return this.post(`agent_api/installment/refuse/${id}`, {
      comment,
      by_client: byClient,
      notice,
      ban_user,
      ...(reject_code_id !== undefined && { reject_code_id }),
    })
  }

  public static getLoanAgentComments(loanNumber: string): AxiosPromise {
    return this.get(`${this.LOAN_PATH}/comments/${loanNumber}`)
  }

  public static createLoanAgentComments(loanNumber: string, comment: string): AxiosPromise {
    return this.post(`${this.LOAN_PATH}/comment/${loanNumber}`, {
      text: comment,
    })
  }

  public static updateLoanComments(commentId: string, comment: string): AxiosPromise {
    return this.put(`${this.LOAN_PATH}/comment/${commentId}?text=${comment}`)
  }

  public static changeStatus({
    comment,
    statusName,
    loanId,
    reject_code_id,
    accept_code_id,
    proposed_other_income,
  }): AxiosPromise {
    return this.post(`agent_api/installment/change_status/${loanId}`, {
      comment,
      status_name: statusName,
      ...(reject_code_id !== undefined && { reject_code_id }),
      ...(accept_code_id !== undefined && { accept_code_id }),
      ...(proposed_other_income !== undefined && { proposed_other_income }),
    })
  }
}
