/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable arrow-body-style */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/dot-notation */
import { useEffect, useState, useContext, useRef, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import StickyBox from 'react-sticky-box'

import { LoanType } from 'features/loans/types'
import { GlobalState } from 'index'
import { NavMenuType } from 'App'
import { setFilters } from 'features/filters/filtersSlice'
import { fetchLoans, fetchLoansCount } from '../../features/loans/extra'
import { resetLoans } from '../../features/loans/loanSlice'
import { Controls } from '../../components/Controls'
import { TableHead, Loader, Search } from '../../components'
import { Table } from './Table'
import { Filters } from './Filters'

import { OPERATOR_LOANS_ROUTE, UNDERWRITER_LOANS_ROUTE } from '../../constants'
import { STRUCTURE } from './Table/tableStructure'
import { COLUMN_TEMPLATE } from './Table/options'
import { AllView } from './Table/MobileView'
import {
  StyledPageWrapper,
  StyledTableWrapper,
  StaledContainer,
  StyledLoaderPage,
  StyledEmptyLabel,
  StyledDesktopTable,
  StyledMobileTable,
  StyledWrapper,
} from './Underwriter.styles'
import { NavigationContext } from 'app/contexts'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const headContent = {
  underwriter: 'Андеррайтер',
  operator: 'Оператор',
}

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const UnderwriterLists = (props: any): JSX.Element => {
  const { claims } = props

  const history: any = useHistory()
  const dispatch = useDispatch()
  const q = useQuery()

  const loans = useSelector((store: GlobalState) => store.loans)
  const { username } = useSelector((store: GlobalState) => store.auth?.user || {})
  const { data: filters } = useSelector((store: GlobalState) => store.filters)
  const { setNavigation, setNavMenuType, focused } = useContext(NavigationContext)
  const prevFocused = usePrevious(focused)
  const location: any = useLocation()

  const [type, setType] = useState(null)
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [scrollTop, setScrollTop] = useState<number>(0)

  useEffect(() => {
    setNavMenuType(NavMenuType[claims])
  }, [])

  useEffect(() => {
    const previousPathname: string = (history.location.state && history.location.state.from) || ''
    if (!previousPathname.includes(claims)) {
      if (claims === 'underwriter') {
        dispatch(
          setFilters({
            loan_status: null,
          })
        )
      } else dispatch(setFilters({}))
    }
  }, [claims, location.pathname])

  useEffect(() => {
    setNavigation({
      activeMenu: null,
      headContent: headContent[claims],
      backTo: false,
    })
  }, [claims])

  const queryTypes = {
    underwriter: LoanType.unassigned,
    operator: LoanType.all,
  }

  useEffect(() => {
    const queryType = q.get('type')
    setType(queryType ? LoanType[queryType] : queryTypes[claims])
  }, [claims])

  useEffect(() => {
    if (loans[type]?.data === null && !loans[type]?.pending) {
      dispatch(fetchLoans({ iteration: 1, type, filters, subject: claims }))
    }

    if (type === 'unassigned') {
      if (!prevFocused && focused && !loans[type]?.pending) {
        setScrollTop(table?.current?.el?.scrollTop || 0)
        dispatch(fetchLoans({ iteration: 1, type, filters, refresh: true }))
        if (!loans.totalAmount?.pending) dispatch(fetchLoansCount())
      }
    }
  }, [focused, filters, loans, dispatch])

  useEffect(() => {
    if (loans[type]?.data !== null) dispatch(resetLoans(null))
  }, [filters])

  const fetchMoreData = () =>
    dispatch(fetchLoans({ iteration: loans[type]?.iteration + 1, type, filters }))

  const toggleFilters = (): void => {
    setShowFilters(!showFilters)
    setShowSearch(false)
  }

  const toggleSearch = (): void => {
    setShowSearch(!showSearch)
    setShowFilters(false)
  }

  const pathNames = {
    underwriter: UNDERWRITER_LOANS_ROUTE,
    operator: OPERATOR_LOANS_ROUTE,
  }

  useEffect(() => {
    const params = new URLSearchParams()
    if (type) {
      params.append('type', type)
      history.push({
        pathname: pathNames[claims],
        search: params.toString(),
        state: { type },
      })
    }
  }, [type, history])

  useEffect(() => {
    if (location) {
      const { type: stateType } = location?.state || {}
      if (stateType) setType(stateType)
    }
  }, [location])

  const filterProps = {
    toggleFilters,
    toggleSearch,
    setShowFilters,
    showFilters,
    showSearch,
    setShowSearch,
    type,
    setType,
    pageControlsStructure: claims,
    actionType: 'underwriter',
    pending: loans[type]?.pending,
  }
  const checkHasMore = () => !loans[type]?.finish

  useEffect(() => {
    // let timer = null
    let unassignedTimer = null
    if (focused) {
      unassignedTimer = setInterval(async () => {
        if (!loans[LoanType.unassigned]?.pending) {
          await dispatch(fetchLoans({ iteration: 1, type: 'unassigned', filters, refresh: true }))
        }
        if (!loans.totalAmount?.pending) dispatch(fetchLoansCount())
      }, 7000)
    }

    return () => {
      clearInterval(unassignedTimer)
    }
  }, [focused, type, filters, dispatch, loans])

  const dataList = Object.values(loans[type]?.data || {})
  const table: any = useRef(null)

  const setScrollTopHandler = () =>
    loans[type]?.pending && setScrollTop(table?.current?.el?.scrollTop || 0)

  const mobileItems = useCallback(
    (loan) => ({
      [LoanType.all]: <AllView loan={loan} type={type} />,
      [LoanType.processing]: <AllView loan={loan} type={type} />,
      [LoanType.unassigned]: <AllView loan={loan} type={type} />,
    }),
    [dataList]
  )

  return (
    <StyledPageWrapper>
      <>
        <Controls {...filterProps} />
        <Filters {...filterProps} />
        <Search {...filterProps} pending={loans[type]?.pending} />
        <div style={{ overflow: 'auto' }} id='scrollable'>
          <StickyBox style={{ zIndex: 999 }}>
            <TableHead
              structure={STRUCTURE[claims]}
              type={type}
              template={COLUMN_TEMPLATE[claims][type]}
            />
          </StickyBox>
          <StyledTableWrapper>
            <>
              {loans[type]?.pending && (
                <StyledLoaderPage top={scrollTop}>
                  <Loader />
                </StyledLoaderPage>
              )}

              <InfiniteScroll
                ref={table}
                dataLength={dataList?.length || 0}
                next={fetchMoreData}
                hasMore={checkHasMore()}
                onScroll={setScrollTopHandler}
                loader={<div />}
                scrollableTarget='scrollable'
              >
                <StyledWrapper>
                  {loans[type]?.data && dataList.length ? (
                    <>
                      {dataList?.map((loan: any, index) => {
                        return (
                          <StaledContainer key={`${loan.loans_loan_id}_${index}`}>
                            <StyledDesktopTable>
                              <Table
                                claims={claims}
                                type={type}
                                pending={loan.pending}
                                status={loan.loan_statuses_status_name}
                                sum={loan.loans_loan_ask_sum}
                                createdAt={loan.loans_loan_date_create}
                                loanId={loan.loans_loan_id}
                                loanNumber={loan.loans_loan_number}
                                passportFirstName={loan.passport_first_name}
                                passportLastName={loan.passport_last_name}
                                passportMiddleName={loan.passport_middle_name}
                                passportNumber={loan.passport_number}
                                passportSerial={loan.passport_serial}
                                regAddress={loan.registration_address}
                                regRegion={loan.residence_address}
                                closedCount={loan.closed_count}
                                userLogin={loan.users_login}
                                utmSource={loan.utm_source}
                                adminUsername={loan.admin_username}
                                username={username}
                                loanProduct={loan.loans_product_id}
                                permittedSum={loan.loans_loan_permitted_sum}
                                statusDate={loan.loan_statuses_status_date}
                              />
                            </StyledDesktopTable>
                            <StyledMobileTable> {mobileItems(loan)[type]}</StyledMobileTable>
                          </StaledContainer>
                        )
                      })}
                    </>
                  ) : (
                    <StyledEmptyLabel>
                      {!loans[type]?.pending && <p>Ничего не найдено</p>}
                    </StyledEmptyLabel>
                  )}
                </StyledWrapper>
              </InfiniteScroll>
            </>
          </StyledTableWrapper>
        </div>
      </>
    </StyledPageWrapper>
  )
}
