import * as Yup from 'yup'

export const validationSchemaPassport = Yup.object().shape({
  serial: Yup.string()
    .required()
    .test((val) => val && !val.includes('_')),
  number: Yup.string()
    .required()
    .test((val) => val && !val.includes('_')),
  date_of_issue: Yup.string().required(),
  place_of_issue: Yup.string().required(),
  code_division: Yup.string()
    .required()
    .test((val) => val && !val.includes('_')),
  last_name: Yup.string().required(),
  first_name: Yup.string().required(),
  middle_name: Yup.string().required(),
  gender: Yup.string().required(),
  date_of_birth: Yup.string().required(),
  place_of_birth: Yup.string().required(),
  previous_passport_data: Yup.string().test((val) => !val || (val && !val.includes('_'))),
})

export const validationSchemaRegistration = Yup.object().shape({
  postal_code: Yup.string()
    .required()
    .test((val) => val && !val.includes('_')),
  region: Yup.string().required(),
  city: Yup.string().required(),
  street: Yup.string().required(),
  house_number: Yup.string().required(),
})

export const validationSchemaAuth = Yup.object().shape({
  username: Yup.string().test('required', 'Данное поле не может быть пустым', (val) => !!val),
  password: Yup.string().test('required', 'Данное поле не может быть пустым', (val) => !!val),
})

export const validationSchemaFilters = Yup.object().shape({
  admin_username: Yup.string().nullable(true),
  start_date: Yup.date().nullable(true),
  stop_date: Yup.date().nullable(true),
  loan_expected_closing_date: Yup.date().nullable(true),
  operator: Yup.string().nullable(true), // disabled
  loan_overdue_days_count: Yup.number().nullable(true), // disabled
  loan_actual_closing_date: Yup.date().nullable(true),
})

const validationSchemaCards = Yup.object().shape({
  card_added_at: Yup.date().required(),
  card_number: Yup.string().test('required', 'required text', (val) => !!val),
  card_holder: Yup.string().required(),
  card_expiration_date: Yup.date().required(),
})

export const validationSchemaCardsInput = Yup.object().shape({
  cards: Yup.array().of(validationSchemaCards),
})

export const validationSchemaDetails = Yup.object().shape({
  education_level: Yup.string().required(),
  employment_type: Yup.string().required(),
  monthly_income: Yup.string().required(),
})

export const validationSchemaPassportAgent = Yup.object().shape({
  login: Yup.string()
    .required()
    .test((val) => val && !val.includes('_')), // phone
  time_zone: Yup.string().test((val) => val && !val.includes('_')),
  email: Yup.string().email().required(), // email
})

export const validationSchemaPersonal = Yup.object().shape({
  login: Yup.string()
    .required()
    .test((val) => val && !val.includes('_')), // phone
  time_zone: Yup.string().test((val) => val && !val.includes('_')),
  email: Yup.string().email().required(), // email
})

export const validationSchemaBankrupt = Yup.object().shape({
  statement_date: Yup.string().required(),
  bankrupt_date: Yup.string().required(),
  is_all_debt_off: Yup.boolean().required(),
  bankrupt_company_name: Yup.string().required(),
  is_deliberate_bankrupt: Yup.boolean().required(),
  is_fraud_credit: Yup.boolean().required(),
  is_bankrupt_credit: Yup.boolean().required(),
  bankrupt_credit_decision: Yup.string().required(),
})

export const validationAdditionalContact = Yup.object().shape({
  number_phone: Yup.string()
    .required()
    .test((val) => val && !val.includes('_')),
  familiar_type: Yup.string().required(),
  last_name: Yup.string().required(),
  first_name: Yup.string().required(),
  middle_name: Yup.string().required(),
})

export const validationAdditionalContactsArray = Yup.object().shape({
  contacts: Yup.array().of(validationAdditionalContact),
})

export const validationSchemaPdnCalculator = Yup.object().shape({
  loanSum: Yup.string().required(),
  percentPerDay: Yup.string().required(),
  otherIncome: Yup.string().required(),
})
