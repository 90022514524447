/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Module } from 'components'
import { INBKITransferData } from 'features/loan/types'
import { fetchNBKITransferData } from 'features/loan/extra'
import { GlobalState } from 'index'

import { StyledNBKIContent } from './Modules.styles'
import { normalizeValues } from 'helpers'

const moduleId = 'transfer'
export const NBKITransfer: any = ({ openedModules, onToggleModule }): JSX.Element => {
  const { userData, nbkiTransferData } = useSelector((store: GlobalState) => store.singleLoan)
  const dispatch = useDispatch()
  const [data, setData] = useState<INBKITransferData>(null)

  const isOpen = openedModules.includes(moduleId)

  useEffect(() => {
    if (isOpen && userData?.customer_id && !nbkiTransferData?.isFetching)
      dispatch(fetchNBKITransferData({ customer_id: String(userData?.customer_id) }))
  }, [isOpen, userData?.customer_id, nbkiTransferData?.isFetching])

  useEffect(() => {
    if (nbkiTransferData?.data) setData(nbkiTransferData?.data)
  }, [nbkiTransferData?.data])

  const handleExpand = (): void => onToggleModule(moduleId)

  return (
    <Formik
      initialValues={null}
      enableReinitialize
      onSubmit={null}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <Module.Wrapper isOpen={isOpen} editable={false} isEdited={false} id={moduleId}>
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>Согласия передачи в БКИ </Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              <StyledNBKIContent>
                <>
                  <Module.Column column={3}>
                    <Module.Title>Дата последнего согласия</Module.Title>
                    <Module.Value>
                      <>
                        {data?.last_change_date ? (
                          <>{normalizeValues(data?.last_change_date, 'source_full_date')}</>
                        ) : (
                          <>{normalizeValues('')}</>
                        )}
                      </>
                    </Module.Value>
                  </Module.Column>
                  <Module.Column column={3}>
                    <Module.Title>Возможна смена согласия</Module.Title>
                    <Module.Value styles={data?.is_bki_change_approve ? 'boldGreen' : ''}>
                      {normalizeValues(data?.is_bki_change_approve, 'bool')}
                    </Module.Value>
                  </Module.Column>
                </>
              </StyledNBKIContent>
            </Module.ContentWrapper>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({})
