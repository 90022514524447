/* eslint-disable indent */
import styled, { css } from 'styled-components/macro'
import { Input } from '../../../components'
import { THEME } from '../../../core/theme'

interface IStyledActionButton {
  fetching?: boolean
}

const STYLED_OPTIONS = {
  boldGreen: {
    color: THEME.COLORS.SUCCESS,
    fontSize: '16px',
    fontWeight: 'bold',
  },
}

export const StyledDocsDivider = styled.div`
  margin-top: 20px;
`

export const StyledHeadTitle = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: ${THEME.COLORS.DARK_BLUE};
  padding: 15px 0;

  @media (max-width: 480px) {
    font-size: 15px;
    line-height: 15px;
  }
`

export const StyledNBKIContent = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px 30px;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);

    & div {
      grid-column: span 1;
    }
  }
`
export const StyledPtiContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 30px;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
`
export const StyledLoanHistoryContent = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;

  @media (max-width: 480px) {
    display: none;
  }
`

export const StyledServicesContent = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`

export const StyledServicesMobileContent = styled.div`
  display: none;
  @media (max-width: 480px) {
    border: 3px solid black;
    display: block;
  }
`

export const StyledHistoryMobileTableWrapper = styled.div`
  display: none;
  flex-direction: column;

  @media (max-width: 480px) {
    display: flex;
  }
`

export const StyledItemTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: #73717e;
  text-align: center;
  margin: 8px;

  @media (max-width: 480px) {
    line-height: 16px;
    margin: 4px;
  }
`

export const StyledDescription = styled.p<{ styles?: string }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #08003a;
  text-align: center;
  margin: 8px;

  ${({ styles }) =>
    styles &&
    css`
      ${STYLED_OPTIONS[styles]}
    `}

  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }
`

export const StyledHistoryMobileItem = styled.div`
  display: grid;
  gap: 5px 0;
  grid-template-columns: 1fr 1fr;
  margin: 15px 20px;
  padding: 15px 12px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
`

export const StyledLoanTransactionContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
`
export const StyledLoanBalanceContent = styled.div`
  padding: 0 32px 12px;
  font-weight: bold;
  min-width: 800px;
  border-bottom: 1px solid #dfe0eb;
`
export const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 250px 250px 1fr;
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
  transition: height 0.5s;
`

export const StyledFsspContent = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
  transition: height 0.5s;
`

export const StyledIIDXContent = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
  transition: height 0.5s;
`

export const StyledIIDXTypesContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
  transition: height 0.5s;
`

export const StyledScoringHistoryContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
  transition: height 0.5s;
`
export const StyledMobileCommentsWrapper = styled.div`
  background: #f5f6fa;
  padding: 20px 0;

  @media (max-width: 480px) {
    margin: -20px;
  }
`

export const StyledMobileSection = styled.div`
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};
  padding-bottom: 15px;
  margin-bottom: 15px;
`

export const StyledMobileDescription = styled.p`
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  color: ${THEME.COLORS.GRAY};
`

export const StyledMobileCommentInfo = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`
export const StyledMobileMessagesInfo = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`

export const StyledMobileValue = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  margin-top: 5px;
`

export const StyledMobileComment = styled.div`
  position: relative;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    min-width: auto;
    padding: 24px;
    margin: 0 16px;
    margin-bottom: 7px;
    background: ${THEME.COLORS.WHITE};
  }
`

export const StyledMobileHistoryItem = styled.div`
  position: relative;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    min-width: auto;
    padding: 24px;
    margin: 0;
    margin-bottom: 7px;
    background: ${THEME.COLORS.WHITE};
  }
`

export const StyledCommentsContent = styled.div`
  display: grid;
  grid-template-columns: 180px 180px 1fr;
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
`

export const StyledMessagesContent = styled.div`
  display: grid;
  grid-template-columns: 180px 180px 180px 180px 180px 1fr;
  gap: 0;
  padding: 0 32px;
  font-weight: bold;
  color: ${THEME.COLORS.BLUE};
  min-width: 800px;
`

export const StyledXLSDownload = styled.div`
  cursor: pointer;
`

export const StyledIIDXSeparator = styled.div`
  border-top: 2px solid rgb(131 131 140);
  display: block;
  margin: 23px 0;
`

export const StyledSelectBlock = styled.div`
  position: relative;
`
export const StyledRefresh = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 30px;
`

export const StyledResetFilter = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: white;
  cursor: pointer;
`
export const StyledBKIDocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
`

export const StyledDocumentBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
`

export const StyledLoaderWrapper = styled.div`
  position: absolute;
  right: -3px;
  top: -2px;
`

export const StyledDocument = styled.div`
  width: fit-content;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid #dce0e7;
  }
`

export const StyledDocumentTitle = styled.p<{
  loadable?: boolean
}>`
  margin: 5px 0;
  margin-right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${THEME.COLORS.BLUE};
    text-decoration: underline;
  }

  ${({ loadable }) =>
    loadable &&
    css`
      opacity: 0.5;
    `}
`

export const StyledEditCommentWrapper = styled.div<{ editable?: boolean }>`
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  border-bottom: 1px dotted ${THEME.COLORS.TABLE_BORDER};

  & svg {
    cursor: pointer;
  }

  ${({ editable }) =>
    editable &&
    css`
      & svg path {
        fill: ${THEME.COLORS.BLUE};
      }
    `}

  @media (max-width: 480px) {
    right: 15px;
    top: 26px;
    border-bottom: none;
  }
`

export const StyledHistoryColumnWrapper = styled.div`
  cursor: pointer;
  &:hover {
    background-color: ${THEME.COLORS.TABLE_HOVER};
  }
  @media (max-width: 480px) {
    display: none;
  }
`
export const StyledLoansDebtWrapper = styled.div``

export const StyledTitleWinActionButton = styled.div`
  display: flex;
  align-items: center;
`

export const StyledActionButton = styled.div<IStyledActionButton>`
  margin-left: 15px;
  font-size: 14px;
  background: #24c665;
  color: white;
  border-radius: 15px;
  padding: 2px 8px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  opacity: 0.8;
  position: relative;
  top: -4px;

  &:hover {
    opacity: 1;
  }

  ${({ fetching }) =>
    fetching &&
    css`
      color: ${THEME.COLORS.LIGHTLY_GRAY};
      pointer-events: none;
      cursor: auto;

      & svg path {
        fill: ${THEME.COLORS.LIGHTLY_GRAY};
      }
    `}

  & svg {
    color: ${THEME.COLORS.WHITE};
    margin-left: 5px;
  }
`

export const StyledSubValue = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: #08003a;
  opacity: 0.5;
`

export const StyledInput = styled(Input)`
  border: 2px solid ${THEME.COLORS.LIGHTLY_GRAY};
  box-sizing: border-box;
  border-radius: 16px;
  ${({ error }) =>
    error
      ? css`
          border: 2px solid ${THEME.COLORS.WARNING}!important;
        `
      : css`
          &:hover {
            border: 2px solid ${THEME.COLORS.YELLOW}!important;
          }
        `}
`

export const StyledButtonBlockModal = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
`

export const StyledSubTitle = styled.p`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${THEME.COLORS.GRAY};
  padding-top: 25px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 35px;
    height: 1px;
    background: ${THEME.COLORS.LIGHTLY_GRAY};
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const StyledValueWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledModalButton = styled.div`
  padding: 2px 12px;
  background: ${THEME.COLORS.YELLOW};
  border-radius: 40px;
  cursor: pointer;
  margin-left: 20px;
  margin: 10px 0;
  margin-left: 15px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);

  &:hover {
    background: ${THEME.COLORS.YELLOW_HOVER};
  }
`
export const StyledHeaderButton = styled.div`
  position: absolute;
  left: 160px;
`

export const StyledLimitWrapper = styled.div`
  padding: 0 32px;
  padding-bottom: 150px;

  @media (max-width: 480px) {
    padding: 0 20px;
  }
`

export const StyledLimitContent = styled.div`
  max-width: 640px;
  background: ${THEME.COLORS.SMOOTH};
  border-radius: 16px;
  padding: 40px 33px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 36px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    max-width: 450px;
    gap: 25px;
    padding: 15px;
  }
`

export const StyledLimitTitle = styled(StyledHeadTitle)`
  margin: 32px 0;

  @media (max-width: 480px) {
    margin: 25px 0;
  }
`

export const StyledRangeTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
  margin-bottom: 20px;
  margin-top: 10px;
`

export const StyledLimitItem = styled.div``

export const StyledLimitButtons = styled.div`
  display: flex;
  margin-top: 15px;
  gap: 15px;

  @media (max-width: 480px) {
    flex-wrap: wrap;
    margin-bottom: 200px;
    gap: 25px;

    & div {
      border: 1px solid #d8d8d8;
    }
  }
`

export const StyledRotateButton = styled.div`
  cursor: pointer;
  display: inline-flex;
  margin: 15px 0;

  &:hover {
    & svg {
      & path {
        fill: ${THEME.COLORS.BLUE};
      }
    }
  }
`

export const StyledEditButton = styled.div<{ editing?: boolean }>`
  cursor: pointer;
  display: inline-flex;
  margin: 15px 0;
  position: absolute;
  right: 28px;
  top: -12px;

  &:hover {
    & svg {
      & path {
        fill: ${THEME.COLORS.BLUE};
      }
    }
  }

  ${({ editing }) =>
    editing &&
    css`
      & svg {
        & path {
          fill: ${THEME.COLORS.BLUE};
        }
      }
    `}
`

export const StyledBottomBlock = styled.div`
  margin-bottom: 50px;
`

export const StyledTextArea = styled.textarea<any>`
  background: ${THEME.COLORS.WHITE};
  border: 1px solid ${THEME.COLORS.LIGHTLY_GRAY};
  box-sizing: border-box;
  border-radius: 16px;
  color: ${THEME.COLORS.GRAY};
  padding: 19px 16px;
  width: 100%;
  max-width: 640px;
  height: 120px;
  resize: none;

  &:focus {
    outline: none;
  }

  @media (max-width: 480px) {
    width: 95%;
  }
`

export const StyledResultWrapper = styled.div`
  display: grid;
  grid-gap: 34px;
  margin-top: 32px;
`
export const StyledUserWrapper = styled.div``

export const StyledTitle = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
`

export const StyledTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  width: fit-content;
  grid-gap: 12px;

  ${StyledTitle} {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: ${THEME.COLORS.DARK_BLUE};
    margin-bottom: 0;
  }
`
export const StyledButtonsWrapper = styled.div`
  a {
    text-decoration: none;
  }
`

export const StyledDateWrapper = styled.div`
  display: grid;
  grid-gap: 100px;
  grid-template-columns: minmax(100px, 200px) minmax(100px, 200px);
  margin-bottom: 16px;
`
export const StyledDate = styled.div``
export const StyledLimitResult = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: ${THEME.COLORS.DARK_BLUE};
`
export const StyledCommentWrapper = styled.div`
  margin: 0 -32px;
  padding: 0 32px 30px 32px;
  border-bottom: 1px solid ${THEME.COLORS.BORDER_LOAN};
`

export const StyledRefuseWrapper = styled.div``
export const StyledRefuseDescription = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${THEME.COLORS.GRAY};
  margin-top: 16px;
`
export const StyledRefuseTypesWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 8px;
`
export const StyledRefuseTypeTitle = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 40px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${THEME.COLORS.BLUE};
      color: ${THEME.COLORS.WHITE};
      cursor: default;
    `};
`
export const StyledRefuseTypeTextWrapper = styled.div`
  margin-top: 40px;
`

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px;
`

export const StyledRefuseButtonWrapper = styled.div`
  margin-top: 36px;
`

export const StyledInspectionsResultsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0 40px;
  grid-column-gap: 75px;
  grid-row-gap: 30px;
`

export const StyledInspectionsResultsItem = styled.div`
  margin-right: 50px;
`

export const StyledInspectionsResultsIcon = styled.div``

export const StyledInspectionsResultsItemTitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 5px 0px;
  display: flex;

  & svg {
    margin-right: 7px;
  }
`

export const StyledInspectionsResultsConclusion = styled.div`
  display: flex;
  padding: 0 40px;
  margin-top: 32px;
  margin-bottom: 20px;
`
export const StyledInspectionsResultsConclusionItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 120px;
`

export const StyledInspectionsResultsConclusionTitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: #828282;
`
export const StyledInspectionsResultsConclusionDescription = styled.p`
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  color: ${THEME.COLORS.BLACK};
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 5px 0px;
`

export const StyledInspectionsResultsDescription = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${THEME.COLORS.DARK_BLUE};
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 5px 0px;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 22px;
`

export const StyledCommentsBlock = styled.div`
  padding: 0 0 30px 30px;

  @media (max-width: 480px) {
    padding: 10px;
  }
`

export const StyledCommentTitle = styled.p`
  margin: 30px 0;
  font-weight: bold;
`

export const StyledCommentsButtonBlock = styled.div`
  margin: 30px;
`

export const StyledImageDescription = styled.div`
  padding-bottom: 8px;

  &:not(:first-child) {
    padding-top: 20px;
  }
`

export const StyledImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 480px) {
    row-gap: 10px;
    justify-content: space-between;
  }
`

export const StyledButtonBlock = styled.div`
  margin: 0;
  padding: 0 32px;
  transition: 0.3s;
`

export const StyledBlockerWrapper = styled.div`
  position: relative;
  height: 80px;
`

export const StyledBlocker = styled.div`
  position: absolute;
  width: 105%;
  height: 110%;
  bottom: -10px;
  z-index: 999;
  opacity: 0.5;
`

export const StyledVideoWrapper = styled.div`
  display: flex;
  padding: 0 32px;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 20px;

  @media (max-width: 480px) {
    padding: 0 15px;
  }
`

export const StyledVideoItem = styled.div`
  max-width: 450px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 10px;
`

export const StyledDescriptionVideo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const StyledMobileHistory = styled.div`
  padding: 0 15px;

  @media (max-width: 480px) {
    padding: 0;
  }
`

export const StyledTableWrapper = styled.div`
  max-width: 350px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  width: 350px;
  margin-right: 25px;

  @media (max-width: 480px) {
    width: 100%;
    margin-right: 0;
  }
`

export const StyledTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px dotted #e2e8f0;

  &:last-child {
    margin-bottom: 0;
  }
`

export const StyledTableData = styled.span<{ isSum?: boolean; isZero?: boolean }>`
  font-weight: 700;
  color: ${({ isSum, isZero }) => {
    if (isZero) return '#b4b4b4'
    return isSum ? '#24c665' : 'black'
  }};
`

export const StyledError = styled.p`
  color: red;
`
export const StyledBanInfo = styled.p`
  margin-top: 15px;
  background: ${THEME.COLORS.RED};
  width: fit-content;
  color: white;
  padding: 3px 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 18px;

  & svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  @media (max-width: 420px) {
    font-size: 14px;
    & svg {
      width: 14px;
      height: 14px;
      margin-right: 3px;
      position: relative;
      top: -1px;
    }
  }
`

export const StyledFemidsDate = styled.span`
  opacity: 0.5;
  font-size: 12px;
`

export const StyledPhotoWrapper = styled.div`
  width: 100%;
  border-right: 1px solid #dfe0eb;
  margin-right: 20px;

  @media (max-width: 480px) {
    width: 100%;
    border-right: 1px solid transparent;
    margin-right: 0;
  }
`

export const StyledPassportPhotoWrapper = styled.div`
  width: 100%;
  border-right: 1px solid #dfe0eb;
  margin-right: 20px;

  @media (max-width: 480px) {
    width: 100%;
    border-right: 1px solid transparent;
    margin-right: 0;
  }
`

export const StyledPassportWrapper = styled.div`
  display: flex;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`

export const StyledPassportPhotoItem = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 480px) {
    row-gap: 10px;
    justify-content: space-between;
  }
`
export const StyledPDNCalculatorContent = styled.div`
  max-width: 450px;
  background: ${THEME.COLORS.SMOOTH};
  border-radius: 16px;
  padding: 40px 33px;
  display: grid;
  grid-template-columns: 1fr 110px;
  gap: 12px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    max-width: 450px;
    gap: 25px;
    padding: 15px;
  }
`

export const StyledPDNCalculatorResult = styled.p`
  font-weight: 700;
  text-align: right;

  @media (max-width: 480px) {
    text-align: left;
  }
`

export const StyledPDNCalculatorTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
`
