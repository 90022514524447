/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios'

import { ServiceBase } from './serviceBase'
import {
  PZDocumentsList,
  SaasDocumentsList,
  SignatureQuestionnairesResponse,
} from 'features/commonDocuments/types'

export interface SaasDocumentsListPayload {
  loan_number: string
  consent_type?: string
}

export interface PZDocumentsListPayload {
  loan_id: string
}

export interface SaasDocument {
  consent_id: string
}

export interface PZDocument {
  id: string
}

export interface SignatureQuestionnairesRequest {
  loan_number: string
}

export interface DownloadSignatureQuestionnairesRequest {
  id: string
}

export class CommonDocumentsService extends ServiceBase {
  protected static ROOT = 'external_sources/consents'

  public static getSaasDocumentsList({
    loan_number,
    consent_type,
  }: SaasDocumentsListPayload): AxiosPromise<SaasDocumentsList[]> {
    return this.get(
      `${this.ROOT}/${loan_number}/saas${consent_type ? `?consent_type=${consent_type}` : ''}`
    )
  }

  public static downloadSaasDocument({ consent_id }: SaasDocument): AxiosPromise<Blob> {
    return this.get(`${this.ROOT}/pdf/${consent_id}/saas`, {
      responseType: 'blob',
      timeout: 30000,
    })
  }

  public static getPZDocumentsList({
    loan_id,
  }: PZDocumentsListPayload): AxiosPromise<PZDocumentsList[]> {
    return this.get(`${this.ROOT}/${loan_id}`)
  }

  public static downloadPZDocument({ id }: PZDocument): AxiosPromise<Blob> {
    return this.get(`${this.ROOT}/pdf/${id}`, {
      responseType: 'blob',
      timeout: 30000,
    })
  }

  public static getSignatureQuestionnaires({
    loan_number,
  }: SignatureQuestionnairesRequest): AxiosPromise<SignatureQuestionnairesResponse[]> {
    return this.get(`${this.ROOT}/signature_questionnaires/${loan_number}/saas`)
  }

  public static downloadSignatureQuestionnaires({
    id,
  }: DownloadSignatureQuestionnairesRequest): AxiosPromise<Blob> {
    return this.get(`external_sources/signature_questionnaires/pdf/{id}/saas?signature_id=${id}`, {
      responseType: 'blob',
      timeout: 30000,
    })
  }
}
