/* eslint-disable object-shorthand */
/* eslint-disable indent */
/* eslint-disable arrow-body-style */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'

import { GlobalState } from 'index'
import { addToast } from 'features/toast/toastSlice'
import { InputField, isValidForm, validationSchemaPdnCalculator } from 'helpers'
import { Button, Module } from 'components'
import { ToastTypes } from 'lib/toast/Toast'

import {
  StyledButtonBlock,
  StyledLimitItem,
  StyledPDNCalculatorContent,
  StyledPDNCalculatorResult,
  StyledPDNCalculatorTitle,
} from './Modules.styles'
import { IModuleProps } from './types'

interface IFormValues {
  loanSum: string
  percentPerDay: string
  loanAskPeriod: string
  otherIncome: string
}

const CREDIT_INFO_EXTERNAL_AUTH_TOKEN = 'Bearer 46|rr2hQtjaXppajONiFdKChlpZESYNVbf5UkDvG9kz625d7617'
const PDL_CALCULATE_LIMIT_API_URL_V2 = 'https://alf.tech.papazaim.com/api/credit-info/get-pti-array'

const moduleId = 'pdnCalculator'

const calculateMonthlyPaymentNotBankrupt = ({ loanSum, percentPerDay, loanAskPeriod }) => {
  return (
    loanSum *
    ((((percentPerDay * 7) / 100) * (1 + (percentPerDay * 7) / 100) ** loanAskPeriod) /
      ((1 + (percentPerDay * 7) / 100) ** loanAskPeriod - 1)) *
    4
  )
}

const calculateMonthlyPaymentBankrupt = ({ loanSum, percentPerDay, loanAskPeriod }) => {
  return (
    loanSum *
    ((((percentPerDay * 14) / 100) * ((1 + (percentPerDay * 14) / 100) ** loanAskPeriod / 2)) /
      ((1 + (percentPerDay * 7) / 100) ** loanAskPeriod / 2 - 1)) *
    2
  )
}

const fetchData = async ({
  onError,
  onSuccess,
  setFetching,
  loanNumber,
  loanSum,
  percentPerDay,
  otherIncome,
  setApiResponse,
  setErrorResponse,
}) => {
  setFetching(true)
  const url = `${PDL_CALCULATE_LIMIT_API_URL_V2}/${loanNumber}?loan_sum=${loanSum}&loan_payment=${percentPerDay}&income=${otherIncome}`

  try {
    const results = await axios
      .get(url, { headers: { Authorization: CREDIT_INFO_EXTERNAL_AUTH_TOKEN } })
      .then((response) => response.data)
    setApiResponse(results)
    setErrorResponse(null)
    onSuccess()
  } catch (err) {
    setErrorResponse(`Ошибка ${err.message}`)
    setFetching(false)
    onError()
  } finally {
    setFetching(false)
  }
}

export const PdnCalculator: React.FC<IModuleProps> = ({
  openedModules,
  onToggleModule,
}): JSX.Element => {
  const dispatch = useDispatch()
  const { loadData, userDetailsData } = useSelector((store: GlobalState) => store.singleLoan)

  const [fetching, setFetching] = useState<boolean>(false)
  const [apiResponse, setApiResponse] = useState<any>(null)
  const [errorResponse, setErrorResponse] = useState<any>(null)
  const [initialValues, setInitialValues] = useState<IFormValues>({
    loanSum: null,
    percentPerDay: null,
    loanAskPeriod: null,
    otherIncome: null,
  })

  useEffect(() => {
    if (
      loadData?.loan_ask_sum &&
      loadData?.product_percent_day &&
      loadData?.loan_ask_period &&
      userDetailsData?.data?.other_income
    ) {
      const isBankruptProduct = loadData?.credit_product?.startsWith('bankrupt_')

      const percentPerDay = isBankruptProduct
        ? String(
            (
              Math.round(
                calculateMonthlyPaymentBankrupt({
                  loanSum: loadData?.loan_ask_sum,
                  percentPerDay: loadData?.product_percent_day,
                  loanAskPeriod: loadData?.loan_ask_period,
                }) * 100
              ) / 100
            ).toFixed(2)
          )
        : String(
            (
              Math.round(
                calculateMonthlyPaymentNotBankrupt({
                  loanSum: loadData?.loan_ask_sum,
                  percentPerDay: loadData?.product_percent_day,
                  loanAskPeriod: loadData?.loan_ask_period,
                }) * 100
              ) / 100
            ).toFixed(2)
          )

      setInitialValues({
        loanSum: String(loadData?.loan_ask_sum),
        percentPerDay,
        loanAskPeriod: String(loadData?.loan_ask_period),
        otherIncome: String(userDetailsData?.data?.other_income),
      })
    }
  }, [
    loadData?.loan_ask_sum,
    loadData?.product_percent_day,
    loadData?.loan_ask_period,
    userDetailsData?.data?.other_income,
    loadData?.credit_product,
  ])

  const isOpen = openedModules.includes(moduleId)

  const handleCancel = (resetForm) => (): void => {
    resetForm()
  }
  const handleExpand = (): void => onToggleModule(moduleId)

  const submitForm = async (values: any): Promise<void> => {
    fetchData({
      setFetching,
      loanNumber: loadData?.loan_number,
      loanSum: values?.loanSum,
      percentPerDay: values?.percentPerDay,
      otherIncome: values?.otherIncome,
      setApiResponse,
      setErrorResponse,
      onError: () => {
        dispatch(
          addToast({
            type: ToastTypes.warning,
            title: 'Ошибка',
            description: `Данные не сохранены`,
          })
        )
      },
      onSuccess: () => {
        dispatch(
          addToast({
            type: ToastTypes.success,
            title: 'Успех',
            description: `Данные умпешно перезапрошены`,
          })
        )
      },
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationSchemaPdnCalculator}
    >
      {(formikProps) => {
        const { errors, dirty, resetForm, handleSubmit } = formikProps

        return (
          <Module.Wrapper isOpen={isOpen} editable isEdited={dirty} id={moduleId}>
            <Module.Header onClick={handleExpand}>
              <Module.Actions isOpen={isOpen}>
                <Module.HeadTitle>Калькулятор ПДН</Module.HeadTitle>
                <Module.Arrow isOpen={isOpen} />
              </Module.Actions>
            </Module.Header>
            <Module.ContentWrapper>
              <>
                <Module.Content>
                  <Edit {...formikProps} />
                </Module.Content>
              </>
              {apiResponse && (
                <div
                  style={{
                    margin: '32px',
                  }}
                >
                  <StyledPDNCalculatorContent>
                    <StyledLimitItem>
                      <StyledPDNCalculatorTitle>Доход:</StyledPDNCalculatorTitle>
                    </StyledLimitItem>
                    <StyledLimitItem>
                      <StyledPDNCalculatorResult>
                        {apiResponse[0]?.income}
                      </StyledPDNCalculatorResult>
                    </StyledLimitItem>

                    <StyledLimitItem>
                      <StyledPDNCalculatorTitle>Дополнительный доход:</StyledPDNCalculatorTitle>
                    </StyledLimitItem>
                    <StyledLimitItem>
                      <StyledPDNCalculatorResult>
                        {apiResponse[0]?.other_sources_income}
                      </StyledPDNCalculatorResult>
                    </StyledLimitItem>

                    <StyledLimitItem>
                      <StyledPDNCalculatorTitle>ПДН:</StyledPDNCalculatorTitle>
                    </StyledLimitItem>
                    <StyledLimitItem>
                      <StyledPDNCalculatorResult>{apiResponse[0]?.PDN}</StyledPDNCalculatorResult>
                    </StyledLimitItem>

                    <StyledLimitItem>
                      <StyledPDNCalculatorTitle>Все платежи:</StyledPDNCalculatorTitle>
                    </StyledLimitItem>
                    <StyledLimitItem>
                      <StyledPDNCalculatorResult>
                        {apiResponse[0]?.allPayments}
                      </StyledPDNCalculatorResult>
                    </StyledLimitItem>

                    <StyledLimitItem>
                      <StyledPDNCalculatorTitle>Суммарный доход:</StyledPDNCalculatorTitle>
                    </StyledLimitItem>
                    <StyledLimitItem>
                      <StyledPDNCalculatorResult>
                        {apiResponse[0]?.totalIncome}
                      </StyledPDNCalculatorResult>
                    </StyledLimitItem>

                    <StyledLimitItem>
                      <StyledPDNCalculatorTitle>
                        Другие платежи по кредитам:
                      </StyledPDNCalculatorTitle>
                    </StyledLimitItem>
                    <StyledLimitItem>
                      <StyledPDNCalculatorResult>
                        {apiResponse[0]?.otherLoansPayment}
                      </StyledPDNCalculatorResult>
                    </StyledLimitItem>

                    <StyledLimitItem>
                      <StyledPDNCalculatorTitle>Доход по БКИ:</StyledPDNCalculatorTitle>
                    </StyledLimitItem>
                    <StyledLimitItem>
                      <StyledPDNCalculatorResult>
                        {apiResponse[0]?.kbrsIncome}
                      </StyledPDNCalculatorResult>
                    </StyledLimitItem>

                    <StyledLimitItem>
                      <StyledPDNCalculatorTitle>Региональный доход:</StyledPDNCalculatorTitle>
                    </StyledLimitItem>
                    <StyledLimitItem>
                      <StyledPDNCalculatorResult>
                        {apiResponse[0]?.regionIncome}
                      </StyledPDNCalculatorResult>
                    </StyledLimitItem>

                    <StyledLimitItem>
                      <StyledPDNCalculatorTitle>Сумма займа:</StyledPDNCalculatorTitle>
                    </StyledLimitItem>
                    <StyledLimitItem>
                      <StyledPDNCalculatorResult>
                        {apiResponse[0]?.loanSum}
                      </StyledPDNCalculatorResult>
                    </StyledLimitItem>

                    <StyledLimitItem>
                      <StyledPDNCalculatorTitle>Источник:</StyledPDNCalculatorTitle>
                    </StyledLimitItem>
                    <StyledLimitItem>
                      <StyledPDNCalculatorResult>external</StyledPDNCalculatorResult>
                    </StyledLimitItem>

                    <StyledLimitItem>
                      <StyledPDNCalculatorTitle>Ежемесячный платеж:</StyledPDNCalculatorTitle>
                    </StyledLimitItem>
                    <StyledLimitItem>
                      <StyledPDNCalculatorResult>
                        {apiResponse[0]?.loanPayment}
                      </StyledPDNCalculatorResult>
                    </StyledLimitItem>
                  </StyledPDNCalculatorContent>
                </div>
              )}
              <StyledButtonBlock>
                <div style={{ margin: '25px 0' }}>
                  <Button
                    disabled={isValidForm(errors) || fetching}
                    type='standardBig'
                    onClick={!fetching ? () => handleSubmit() : null}
                    pending={fetching}
                  >
                    Запросить
                  </Button>
                  {dirty && (
                    <Button type='emptyBig' onClick={handleCancel(resetForm)}>
                      Отменить
                    </Button>
                  )}
                </div>
              </StyledButtonBlock>
            </Module.ContentWrapper>
          </Module.Wrapper>
        )
      }}
    </Formik>
  )
}

const Edit = (props): JSX.Element => {
  const { values, setFieldValue } = props

  return (
    <>
      <Module.Column column={1}>
        <InputField
          name='loanSum'
          onChange={setFieldValue}
          placeholder='Сумма займа'
          value={values.loanSum}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='percentPerDay'
          onChange={setFieldValue}
          placeholder='Ежемесячный платеж'
          value={values.percentPerDay}
          {...props}
        />
      </Module.Column>
      <Module.Column column={1}>
        <InputField
          name='otherIncome'
          onChange={setFieldValue}
          placeholder='Дополнительный доход клиента'
          value={values.otherIncome}
          {...props}
        />
      </Module.Column>
    </>
  )
}
