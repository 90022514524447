/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  CommonDocumentsService,
  SaasDocumentsListPayload,
  PZDocumentsListPayload,
  SignatureQuestionnairesRequest,
} from 'services/commonDocumentService'

import { PZDocumentsList, SaasDocumentsList, SignatureQuestionnairesResponse } from './types'

interface WithCallbacks {
  onSuccess?: () => void
  onError?: () => void
}

interface SaasDocumentsListThunk extends SaasDocumentsListPayload, WithCallbacks {}

interface PZDocumentsListThunk extends PZDocumentsListPayload, WithCallbacks {}

interface SignatureQuestionnairesListThunk extends SignatureQuestionnairesRequest, WithCallbacks {}

export const getSaasDocumentsList = createAsyncThunk<SaasDocumentsList[], SaasDocumentsListThunk>(
  'commonDocuments/getSaasDocumentsList',
  async (props) => {
    const { loan_number, consent_type, onError, onSuccess } = props
    const response = await CommonDocumentsService.getSaasDocumentsList({
      loan_number,
      consent_type,
    })
      .then(({ data }) => {
        onSuccess && onSuccess()
        return data
      })
      .catch((e) => {
        onError && onError()
        throw new Error(e)
      })
    return response
  }
)

export const getPZDocumentsList = createAsyncThunk<PZDocumentsList[], PZDocumentsListThunk>(
  'commonDocuments/getPZDocumentsList',
  async (props) => {
    const { loan_id, onError, onSuccess } = props
    const response = await CommonDocumentsService.getPZDocumentsList({
      loan_id,
    })
      .then(({ data }) => {
        onSuccess && onSuccess()
        return data
      })
      .catch((e) => {
        onError && onError()
        throw new Error(e)
      })
    return response
  }
)

export const getSignatureQuestionnaires = createAsyncThunk<
  SignatureQuestionnairesResponse[],
  SignatureQuestionnairesListThunk
>('commonDocuments/getSignatureQuestionnaires', async (props) => {
  const { loan_number, onError, onSuccess } = props
  const response = await CommonDocumentsService.getSignatureQuestionnaires({
    loan_number,
  })
    .then(({ data }) => {
      onSuccess && onSuccess()
      return data
    })
    .catch((e) => {
      onError && onError()
      throw new Error(e)
    })
  return response
})
