import { CreditProduct } from 'constants/creditProduct'

export const optionMatrix = {
  [CreditProduct.installment_30_100k]: [
    {
      title: 'Повторный ',
      value: '#Повторный',
    },
    {
      title: 'Повторный Дистанционный',
      value: '#Повторный дистанционный',
    },
    {
      title: 'Тор-Ар',
      value: '#Top-Up',
    },
    {
      title: 'Тор-Ар Дистанционный',
      value: '#Top-Up дистанционный',
    },
    {
      title: 'База Installment',
      value: '#База',
    },
    {
      title: 'База Installment Дистанционный ',
      value: '#База дистанционный',
    },
  ],
  [CreditProduct.installment_20_30]: [
    {
      title: 'Дистанционный',
      value: '#Дистанционный',
    },
    {
      title: 'Повторный Дистанционный',
      value: '#Повторный дистанционный',
    },
    {
      title: 'Оффлайн',
      value: '#Оффлайн',
    },
    {
      title: 'Повторный Оффлайн',
      value: '#Повторный оффлайн',
    },
  ],
  [CreditProduct.installment_30_50k]: [
    {
      title: 'База Installment',
      value: '#База',
    },
    {
      title: 'База Installment Дистанционный',
      value: '#База дистанционный',
    },
    {
      title: 'Первичный',
      value: '#Первичный',
    },
    {
      title: 'Первичный Дистанционный',
      value: '#Первичный дистанционный',
    },
    {
      title: 'Повторный ',
      value: '#Повторный',
    },
    {
      title: 'Повторный Дистанционный',
      value: '#Повторный дистанционный',
    },
  ],
}

const approveSetOne = []

const approveSetTwo = [
  {
    title: 'Топ-Ап',
    value: '#Топ_Ап#',
  },
  {
    title: 'Топ-Ап_дистанционный',
    value: '#Топ_Ап_Дистант#',
  },
  {
    title: 'База Installment',
    value: '#БазаInst#',
  },
  {
    title: 'База Installment_ дистанционный',
    value: '#БазаInst_Дистант#',
  },
  {
    title: 'Перс. канал',
    value: '#ПерсКанал#',
  },
  {
    title: 'Перс. канал_ дистанционный',
    value: '#ПерсКанал_Дистант#',
  },
  {
    title: 'Повторный займ',
    value: '#ПовторныйЗайм#',
  },
  {
    title: 'Повторный займ_ дистанционный',
    value: '#ПовторныйЗайм_Дистант#',
  },
]

export const APPROVE_DATA = {
  [CreditProduct.installment_20_30]: [...approveSetOne],
  [CreditProduct.installment_16_30k_08_25w]: [...approveSetOne],
  [CreditProduct.installment_31_50k_073_25w]: [...approveSetOne],
  [CreditProduct.installment_51_70k_068_25w]: [...approveSetOne],
  [CreditProduct.installment_16_30k_08_25w_agent]: [...approveSetOne],

  [CreditProduct.installment_30_40k_055_36w]: [...approveSetTwo],
  [CreditProduct.installment]: [...approveSetTwo],
  [CreditProduct.installment_9k]: [...approveSetTwo],
  [CreditProduct.installment_2]: [...approveSetTwo],
  [CreditProduct.installment_3]: [...approveSetTwo],
  [CreditProduct.installment_20_30_1]: [...approveSetTwo],
  [CreditProduct.installment_11_19k]: [...approveSetTwo],
  [CreditProduct.installment_30_50k]: [...approveSetTwo],
  [CreditProduct.installment_30_100k]: [...approveSetTwo],
  [CreditProduct.installment_30_100k_038_52w]: [...approveSetTwo],
  [CreditProduct.installment_30_100k_031_52w]: [...approveSetTwo],
  [CreditProduct.installment_30_50k_distant]: [...approveSetTwo],
  [CreditProduct.installment_30_100k_distant]: [...approveSetTwo],
  [CreditProduct.installment_20_30k_distant]: [...approveSetTwo],
  [CreditProduct.installment_30_50k_054_52w]: [...approveSetTwo],
  [CreditProduct.installment_30_40k_05_36w]: [...approveSetTwo],
  [CreditProduct.installment_30_50k_048_52w]: [...approveSetTwo],
  [CreditProduct.installment_30_50k_054_52w_limit]: [...approveSetTwo],
  [CreditProduct.installment_30_100k_053_52w]: [...approveSetTwo],
  [CreditProduct.installment_71_150k_05_78w]: [...approveSetTwo],

  [CreditProduct.bankrupt_30_100k_05_52w]: [...approveSetTwo],
  [CreditProduct.bankrupt_30_100k_055_52w]: [...approveSetTwo],
  [CreditProduct.bankrupt_30_50k_08_24w]: [...approveSetTwo],
  [CreditProduct.bankrupt_20_30k_08_12w]: [...approveSetTwo],
  [CreditProduct.bankrupt_20_30k_08_12w_agent]: [...approveSetTwo],
  [CreditProduct.bankrupt_30_100k_038_52w]: [...approveSetTwo],
  [CreditProduct.bankrupt_30_100k_031_52w]: [...approveSetTwo],
  [CreditProduct.bankrupt_30_100k_053_52w]: [...approveSetTwo],
}

const refuseSetOne = [
  {
    title: 'Нет контакт',
    value: '#НетКонтакта#',
    banUser: false,
    reject_code_id: 11,
  },
  {
    title: 'Некорректный продукт',
    value: '#НекорректныйПродукт#',
    banUser: false,
    reject_code_id: 9,
  },
  {
    title: 'Скорбалл',
    value: '#Скорбалл#',
    banUser: true,
    reject_code_id: 12,
  },
  {
    title: 'Доп. Контакт',
    value: '#Доп.Контакт#',
    banUser: true,
    reject_code_id: 13,
  },
  {
    title: 'ФССП',
    value: '#ФССП#',
    banUser: true,
    reject_code_id: 14,
  },
  {
    title: 'Мутный клиент',
    value: '#МутныйКлиент#',
    banUser: true,
    reject_code_id: 15,
  },
  {
    title: 'КИ',
    value: '#КИ#',
    banUser: true,
    reject_code_id: 16,
  },
  {
    title: 'Риск выдачи',
    value: '#РискВыдачи#',
    banUser: true,
    reject_code_id: 17,
  },
  {
    title: 'Занятость не подтверждена',
    value: '#ЗанятостьНеПодтверждена#',
    banUser: true,
    reject_code_id: 18,
  },
  {
    title: 'ПДН',
    value: '#PTI#',
    allowComments: true,
    reject_code_id: 30,
    banUser: true,
  },
]

const refuseSetTwo = [
  {
    title: 'Некорректный продукт',
    value: '#НекорректныйПродукт#',
    banUser: false,
    reject_code_id: 9,
  },
  {
    title: 'Есть действующий займ',
    value: '#ЕстьДействующийЗайм/Заявка#',
    banUser: true,
    reject_code_id: 19,
  },
  {
    title: 'ФССП',
    value: '#ФССП#',
    banUser: true,
    reject_code_id: 14,
  },
  {
    title: 'Нет контакта',
    value: '#НетКонтакта#',
    banUser: false,
    reject_code_id: 11,
  },
  {
    title: 'Занятость не подтверждена',
    value: '#ЗанятостьНеПодтверждена#',
    banUser: true,
    reject_code_id: 18,
  },
  {
    title: 'Другая',
    value: '#Иное_отказ#',
    banUser: true,
    reject_code_id: 5,
  },
  {
    title: 'ПДН',
    value: '#PTI#',
    allowComments: true,
    reject_code_id: 30,
    banUser: true,
  },
]

export const revisionData = [
  {
    title: 'Видео',
    value: '#Видео#',
    allowComments: true,
    reject_code_id: 20,
  },
  {
    title: 'Фото первой страницы',
    value: '#ФотоПервойСтраницы#',
    allowComments: true,
    reject_code_id: 26,
  },
  {
    title: 'Доп. Доход',
    value: '#Доп. Доход#',
    allowComments: true,
    reject_code_id: 29,
    proposed_other_income: true,
  },
  {
    title: 'Фото регистрации',
    value: '#ФотоРегистрации#',
    allowComments: true,
    reject_code_id: 25,
  },
  {
    title: 'Фото карты',
    value: '#ФотоКарты#',
    allowComments: true,
    reject_code_id: 27,
  },
  {
    title: 'Карта',
    value: '#Карта#',
    allowComments: true,
    reject_code_id: 22,
  },
  {
    title: 'Нет контакта',
    value: '#НетКонтакта#',
    allowComments: true,
    reject_code_id: 11,
  },
]

export const revisionDataSetTwo = [
  ...revisionData,
  {
    title: 'Выгрузка НБКИ',
    value: '#Выгрузка НБКИ',
    allowComments: true,
    reject_code_id: 28,
  },
]

export const REVISION_DATA = {
  [CreditProduct.installment_20_30]: [...revisionData],
  [CreditProduct.installment_16_30k_08_25w_agent]: [...revisionData],
  [CreditProduct.installment_30_40k_055_36w]: [...revisionData],
  [CreditProduct.installment_30_40k_05_36w]: [...revisionData],
  [CreditProduct.installment]: [...revisionData],
  [CreditProduct.installment_9k]: [...revisionData],
  [CreditProduct.installment_2]: [...revisionData],
  [CreditProduct.installment_3]: [...revisionData],
  [CreditProduct.installment_20_30_1]: [...revisionData],
  [CreditProduct.installment_11_19k]: [...revisionData],
  [CreditProduct.installment_30_50k]: [...revisionData],
  [CreditProduct.installment_30_40k_05_36w]: [...revisionData],
  [CreditProduct.installment_30_50k_048_52w]: [...revisionData],
  [CreditProduct.installment_30_100k]: [...revisionData],
  [CreditProduct.installment_30_50k_distant]: [...revisionData],
  [CreditProduct.installment_30_100k_distant]: [...revisionData],
  [CreditProduct.installment_20_30k_distant]: [...revisionData],
  [CreditProduct.installment_30_50k_054_52w]: [...revisionData],
  [CreditProduct.installment_30_50k_054_52w_limit]: [...revisionData],

  [CreditProduct.installment_16_30k_08_25w]: [...revisionData],
  [CreditProduct.installment_31_50k_073_25w]: [...revisionData],
  [CreditProduct.installment_51_70k_068_25w]: [...revisionData],
  [CreditProduct.installment_30_100k_038_52w]: [...revisionData],
  [CreditProduct.installment_30_100k_031_52w]: [...revisionData],

  [CreditProduct.installment_30_100k_053_52w]: [...revisionData],
  [CreditProduct.installment_71_150k_05_78w]: [...revisionData],
  [CreditProduct.bankrupt_30_100k_053_52w]: [...revisionData],

  [CreditProduct.bankrupt_30_100k_055_52w]: [...revisionDataSetTwo],
  [CreditProduct.bankrupt_30_50k_08_24w]: [...revisionDataSetTwo],
  [CreditProduct.bankrupt_30_100k_05_52w]: [...revisionDataSetTwo],
  [CreditProduct.bankrupt_20_30k_08_12w]: [...revisionDataSetTwo],
  [CreditProduct.bankrupt_20_30k_08_12w_agent]: [...revisionDataSetTwo],
  [CreditProduct.bankrupt_30_100k_038_52w]: [...revisionDataSetTwo],
  [CreditProduct.bankrupt_30_100k_031_52w]: [...revisionDataSetTwo],
}

export const REFUSE_DATA = {
  [CreditProduct.installment_20_30]: [...refuseSetOne],
  [CreditProduct.installment_16_30k_08_25w]: [...refuseSetOne],
  [CreditProduct.installment_31_50k_073_25w]: [...refuseSetOne],
  [CreditProduct.installment_51_70k_068_25w]: [...refuseSetOne],
  [CreditProduct.installment_16_30k_08_25w_agent]: [...refuseSetOne],

  [CreditProduct.installment_30_40k_055_36w]: [...refuseSetTwo],
  [CreditProduct.installment]: [...refuseSetTwo],
  [CreditProduct.installment_9k]: [...refuseSetTwo],
  [CreditProduct.installment_2]: [...refuseSetTwo],
  [CreditProduct.installment_3]: [...refuseSetTwo],
  [CreditProduct.installment_20_30_1]: [...refuseSetTwo],
  [CreditProduct.installment_11_19k]: [...refuseSetTwo],
  [CreditProduct.installment_30_50k]: [...refuseSetTwo],
  [CreditProduct.installment_30_100k]: [...refuseSetTwo],
  [CreditProduct.installment_30_50k_distant]: [...refuseSetTwo],
  [CreditProduct.installment_30_100k_distant]: [...refuseSetTwo],
  [CreditProduct.installment_20_30k_distant]: [...refuseSetTwo],
  [CreditProduct.installment_30_100k_038_52w]: [...refuseSetTwo],
  [CreditProduct.installment_30_100k_031_52w]: [...refuseSetTwo],
  [CreditProduct.installment_30_50k_054_52w]: [...refuseSetTwo],
  [CreditProduct.installment_30_40k_05_36w]: [...refuseSetTwo],
  [CreditProduct.installment_30_50k_048_52w]: [...refuseSetTwo],
  [CreditProduct.installment_30_50k_054_52w_limit]: [...refuseSetTwo],

  [CreditProduct.installment_30_100k_053_52w]: [...refuseSetTwo],
  [CreditProduct.installment_71_150k_05_78w]: [...refuseSetTwo],
  [CreditProduct.bankrupt_30_100k_053_52w]: [...refuseSetTwo],

  [CreditProduct.bankrupt_30_100k_05_52w]: [...refuseSetTwo],
  [CreditProduct.bankrupt_30_100k_055_52w]: [...refuseSetTwo],
  [CreditProduct.bankrupt_30_50k_08_24w]: [...refuseSetTwo],
  [CreditProduct.bankrupt_20_30k_08_12w]: [...refuseSetTwo],
  [CreditProduct.bankrupt_20_30k_08_12w_agent]: [...refuseSetTwo],
  [CreditProduct.bankrupt_30_100k_038_52w]: [...refuseSetTwo],
  [CreditProduct.bankrupt_30_100k_031_52w]: [...refuseSetTwo],
}
