/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
// import { CreditProductTranslate } from '../../constants'

interface IFilterState {
  data: {
    [key: string]: string | any
    search?: string
  }
}

export const initialFiltersState: IFilterState = {
  data: {},
}

export const agentFiltersSlice = createSlice({
  name: 'agentFilters',
  initialState: initialFiltersState,
  reducers: {
    setAgentsFilters: (state, { payload }) => {
      state.data = payload
    },
  },
})

export const { setAgentsFilters } = agentFiltersSlice.actions

export default agentFiltersSlice.reducer
