/* eslint-disable guard-for-in */
import { CreditProduct } from '../../../../constants'

export const AMOUNT = {
  [CreditProduct.installment]: {
    MIN: 1000,
    MAX: 100000,
    DEFAULT: 1500,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_16_30k_08_25w]: {
    MIN: 16000,
    MAX: 30000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.bankrupt_20_30k_08_12w_agent]: {
    MIN: 20000,
    MAX: 30000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_16_30k_08_25w_agent]: {
    MIN: 16000,
    MAX: 30000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_2]: {
    MIN: 20000,
    MAX: 50000,
    DEFAULT: 20000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_3]: {
    MIN: 20000,
    MAX: 50000,
    DEFAULT: 20000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_20_30]: {
    MIN: 20000,
    MAX: 30000,
    DEFAULT: 20000,
    STEP: 1000,
    DISABLED: false,
  },
  [CreditProduct.installment_20_30_1]: {
    MIN: 20000,
    MAX: 30000,
    DEFAULT: 20000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_11_19k]: {
    MIN: 11000,
    MAX: 19000,
    DEFAULT: 11000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_30_50k]: {
    MIN: 30000,
    MAX: 50000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_30_100k]: {
    MIN: 30000,
    MAX: 100000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_30_50k_054_52w]: {
    MIN: 30000,
    MAX: 50000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_30_40k_055_36w]: {
    MIN: 30000,
    MAX: 50000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.bankrupt_30_100k_055_52w]: {
    MIN: 30000,
    MAX: 50000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.bankrupt_30_50k_08_24w]: {
    MIN: 30000,
    MAX: 50000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },

  [CreditProduct.installment_30_40k_05_36w]: {
    MIN: 30000,
    MAX: 40000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },

  [CreditProduct.installment_30_50k_048_52w]: {
    MIN: 30000,
    MAX: 50000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },

  [CreditProduct.bankrupt_30_100k_05_52w]: {
    MIN: 30000,
    MAX: 100000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },

  [CreditProduct.bankrupt_20_30k_08_12w]: {
    MIN: 20000,
    MAX: 30000,
    DEFAULT: 20000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_30_50k_054_52w_limit]: {
    MIN: 30000,
    MAX: 50000,
    DEFAULT: 30000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_31_50k_073_25w]: {
    MIN: 31000,
    MAX: 50000,
    DEFAULT: 50000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_51_70k_068_25w]: {
    MIN: 51000,
    MAX: 70000,
    DEFAULT: 50000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_30_100k_038_52w]: {
    MIN: 30000,
    MAX: 100000,
    DEFAULT: 100000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_30_100k_031_52w]: {
    MIN: 30000,
    MAX: 100000,
    DEFAULT: 50000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.bankrupt_30_100k_038_52w]: {
    MIN: 30000,
    MAX: 100000,
    DEFAULT: 100000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.bankrupt_30_100k_031_52w]: {
    MIN: 30000,
    MAX: 100000,
    DEFAULT: 50000,
    STEP: 1000,
    DISABLED: true,
  },
  [CreditProduct.installment_30_100k_053_52w]: {
    MIN: 30000,
    MAX: 100000,
    DEFAULT: 50000,
    STEP: 1000,
    DISABLED: false,
  },
  [CreditProduct.installment_71_150k_05_78w]: {
    MIN: 71000,
    MAX: 150000,
    DEFAULT: 150000,
    STEP: 1000,
    DISABLED: false,
  },
  [CreditProduct.bankrupt_30_100k_053_52w]: {
    MIN: 30000,
    MAX: 100000,
    DEFAULT: 50000,
    STEP: 1000,
    DISABLED: false,
  },
  [CreditProduct.installment_30_50k_distant]: {
    MIN: 30000,
    MAX: 50000,
    DEFAULT: 50000,
    STEP: 1000,
    DISABLED: false,
  },
}

export const TERM = {
  [CreditProduct.installment]: {
    MIN: 1,
    MAX: 52,
    DEFAULT: 10,
  },
  [CreditProduct.bankrupt_20_30k_08_12w_agent]: {
    MIN: 12,
    MAX: 12,
    DEFAULT: 12,
  },
  [CreditProduct.installment_16_30k_08_25w_agent]: {
    MIN: 25,
    MAX: 25,
    DEFAULT: 25,
  },
  [CreditProduct.bankrupt_30_100k_038_52w]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.bankrupt_30_100k_031_52w]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.installment_30_100k_038_52w]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.installment_30_100k_031_52w]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.installment_16_30k_08_25w]: {
    MIN: 25,
    MAX: 25,
    DEFAULT: 25,
  },
  [CreditProduct.bankrupt_20_30k_08_12w]: {
    MIN: 12,
    MAX: 12,
    DEFAULT: 12,
  },
  [CreditProduct.installment_31_50k_073_25w]: {
    MIN: 25,
    MAX: 25,
    DEFAULT: 25,
  },
  [CreditProduct.installment_51_70k_068_25w]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.installment_2]: {
    MIN: 36,
    MAX: 36,
    DEFAULT: 36,
  },
  [CreditProduct.installment_3]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.installment_20_30]: {
    MIN: 25,
    MAX: 25,
    DEFAULT: 25,
  },
  [CreditProduct.installment_20_30_1]: {
    MIN: 25,
    MAX: 25,
    DEFAULT: 25,
  },
  [CreditProduct.installment_11_19k]: {
    MIN: 13,
    MAX: 13,
    DEFAULT: 13,
  },
  [CreditProduct.installment_30_50k]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.installment_30_100k]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.installment_30_50k_054_52w]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.installment_30_40k_055_36w]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.bankrupt_30_100k_055_52w]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.bankrupt_30_50k_08_24w]: {
    MIN: 24,
    MAX: 24,
    DEFAULT: 24,
  },

  [CreditProduct.installment_30_40k_05_36w]: {
    MIN: 36,
    MAX: 36,
    DEFAULT: 36,
  },

  [CreditProduct.installment_30_50k_048_52w]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },

  [CreditProduct.bankrupt_30_100k_05_52w]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },

  [CreditProduct.installment_30_50k_054_52w_limit]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.installment_30_100k_053_52w]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.installment_71_150k_05_78w]: {
    MIN: 78,
    MAX: 78,
    DEFAULT: 78,
  },
  [CreditProduct.bankrupt_30_100k_053_52w]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
  [CreditProduct.installment_30_50k_distant]: {
    MIN: 52,
    MAX: 52,
    DEFAULT: 52,
  },
}

export const CAN_BE_RETURNED_TO_REWORK = [
  CreditProduct.installment,
  CreditProduct.installment_9k,
  CreditProduct.installment_2,
  CreditProduct.installment_3,
  CreditProduct.installment_20_30_1,
  CreditProduct.installment_11_19k,
  CreditProduct.installment_30_50k,
  CreditProduct.installment_30_40k_05_36w,
  CreditProduct.installment_30_50k_048_52w,
  CreditProduct.installment_30_100k,
  CreditProduct.installment_30_50k_distant,
  CreditProduct.installment_30_100k_distant,
  CreditProduct.installment_20_30k_distant,
  CreditProduct.installment_30_50k_054_52w,
  CreditProduct.installment_30_40k_055_36w,
  CreditProduct.installment_30_50k_054_52w_limit,
  CreditProduct.installment_31_50k_073_25w,
  CreditProduct.installment_51_70k_068_25w,
  CreditProduct.installment_30_100k_038_52w,
  CreditProduct.installment_30_100k_031_52w,
  CreditProduct.bankrupt_30_100k_038_52w,
  CreditProduct.bankrupt_30_100k_031_52w,
  CreditProduct.installment_16_30k_08_25w_agent,

  CreditProduct.bankrupt_20_30k_08_12w,
  CreditProduct.bankrupt_20_30k_08_12w_agent,
  CreditProduct.bankrupt_30_100k_055_52w,
  CreditProduct.bankrupt_30_50k_08_24w,
  CreditProduct.bankrupt_30_100k_05_52w,
  CreditProduct.installment_30_100k_053_52w,
  CreditProduct.installment_71_150k_05_78w,
  CreditProduct.bankrupt_30_100k_053_52w,
]

export const CAN_BE_REFUSED_BY_CLIENT = [
  CreditProduct.installment,
  CreditProduct.installment_9k,
  CreditProduct.installment_2,
  CreditProduct.installment_3,
  CreditProduct.installment_20_30,
  CreditProduct.installment_20_30_1,
  CreditProduct.installment_11_19k,
  CreditProduct.installment_30_50k,
  CreditProduct.installment_30_40k_05_36w,
  CreditProduct.installment_30_50k_048_52w,
  CreditProduct.installment_30_100k,
  CreditProduct.installment_30_50k_distant,
  CreditProduct.installment_30_100k_distant,
  CreditProduct.installment_20_30k_distant,
  CreditProduct.installment_30_50k_054_52w,
  CreditProduct.installment_30_40k_055_36w,
  CreditProduct.installment_30_50k_054_52w_limit,

  CreditProduct.bankrupt_30_100k_05_52w,
  CreditProduct.bankrupt_30_100k_055_52w,
  CreditProduct.bankrupt_30_50k_08_24w,
  CreditProduct.bankrupt_20_30k_08_12w,
  CreditProduct.bankrupt_20_30k_08_12w_agent,
  CreditProduct.installment_30_100k_053_52w,
  CreditProduct.installment_71_150k_05_78w,
  CreditProduct.bankrupt_30_100k_053_52w,
]

const approveSetOne = [
  {
    title: 'Шиндер (откат)',
    value: '#Шиндлер#',
    allowComments: true,
  },
  {
    title: 'Есть контакт',
    value: '#ЕстьКонтакт#',
    allowComments: true,
  },
  {
    title: 'Нет контакта',
    value: '#НетКонтакта#',
    allowComments: true,
  },
]

const approveSetTwo = [
  {
    title: 'Шиндер (откат)',
    value: '#Шиндлер#',
    allowComments: true,
  },
  {
    title: 'Топ – Ап',
    value: '#Топ_Ап#',
    allowComments: true,
  },
  {
    title: 'База Installment',
    value: '#БазаInst#',
    allowComments: true,
  },
  {
    title: 'Перс. канал',
    value: '#ПерсКанал#',
    allowComments: true,
  },
  {
    title: 'Повторный займ',
    value: '#ПовторныйЗайм#',
    allowComments: true,
  },
]

export const APPROVE_DATA = {
  [CreditProduct.installment_20_30]: [...approveSetOne],
  [CreditProduct.installment_16_30k_08_25w]: [...approveSetOne],
  [CreditProduct.installment_31_50k_073_25w]: [...approveSetOne],
  [CreditProduct.installment_51_70k_068_25w]: [...approveSetOne],

  [CreditProduct.installment_30_40k_055_36w]: [...approveSetTwo],
  [CreditProduct.installment]: [...approveSetTwo],
  [CreditProduct.installment_9k]: [...approveSetTwo],
  [CreditProduct.installment_2]: [...approveSetTwo],
  [CreditProduct.installment_3]: [...approveSetTwo],
  [CreditProduct.installment_20_30_1]: [...approveSetTwo],
  [CreditProduct.installment_11_19k]: [...approveSetTwo],
  [CreditProduct.installment_30_50k]: [...approveSetTwo],
  [CreditProduct.installment_30_40k_05_36w]: [...approveSetTwo],
  [CreditProduct.installment_30_50k_048_52w]: [...approveSetTwo],

  [CreditProduct.installment_30_100k]: [...approveSetTwo],
  [CreditProduct.installment_30_100k_038_52w]: [...approveSetTwo],
  [CreditProduct.installment_30_100k_031_52w]: [...approveSetTwo],
  [CreditProduct.installment_30_50k_distant]: [...approveSetTwo],
  [CreditProduct.installment_30_100k_distant]: [...approveSetTwo],
  [CreditProduct.installment_20_30k_distant]: [...approveSetTwo],
  [CreditProduct.installment_30_50k_054_52w]: [...approveSetTwo],
  [CreditProduct.installment_30_50k_054_52w_limit]: [...approveSetTwo],
  [CreditProduct.bankrupt_30_100k_055_52w]: [...approveSetTwo],
  [CreditProduct.bankrupt_30_50k_08_24w]: [...approveSetTwo],
  [CreditProduct.bankrupt_30_100k_05_52w]: [...approveSetTwo],
  [CreditProduct.bankrupt_20_30k_08_12w]: [...approveSetTwo],
  [CreditProduct.bankrupt_20_30k_08_12w_agent]: [...approveSetTwo],
  [CreditProduct.bankrupt_30_100k_038_52w]: [...approveSetTwo],
  [CreditProduct.bankrupt_30_100k_031_52w]: [...approveSetTwo],
  [CreditProduct.installment_30_100k_053_52w]: [...approveSetTwo],
  [CreditProduct.installment_71_150k_05_78w]: [...approveSetTwo],
  [CreditProduct.bankrupt_30_100k_053_52w]: [...approveSetTwo],
}

const refuseSetOne = [
  {
    title: 'Нет контакта',
    value: '#НетКонтакта#',
    allowComments: true,
    reject_code_id: 11,
    banUser: true,
  },
  {
    title: 'Видео',
    value: '#Видео#',
    allowComments: true,
    reject_code_id: 20,
    banUser: true,
  },
  {
    title: 'Некорректный продукт',
    value: '#НекорректныйПродукт#',
    allowComments: true,
    reject_code_id: 9,
    banUser: false,
  },
  {
    title: 'Документы',
    value: '#Документы#',
    allowComments: true,
    reject_code_id: 21,
    banUser: true,
  },
  {
    title: 'Скорбалл',
    value: '#Скорбалл#',
    allowComments: true,
    reject_code_id: 12,
    banUser: true,
  },
  {
    title: 'Карта',
    value: '#Карта#',
    allowComments: true,
    reject_code_id: 22,
    banUser: true,
  },
  {
    title: 'Не подавал заявку',
    value: '#Не_обращался#',
    allowComments: true,
    reject_code_id: 24,
    banUser: false,
  },
  {
    title: 'ФССП',
    value: '#ФССП#',
    allowComments: true,
    reject_code_id: 14,
    banUser: true,
  },
  {
    title: 'Мутный клиент',
    value: '#МутныйКлиент#',
    allowComments: true,
    reject_code_id: 15,
    banUser: true,
  },
  {
    title: 'Есть действующий займ',
    value: '#ЕстьДействующийЗайм/Заявка#',
    allowComments: true,
    reject_code_id: 19,
    banUser: false,
  },
  {
    title: 'ПДН',
    value: '#PTI#',
    allowComments: true,
    reject_code_id: 30,
    banUser: true,
  },
]

const refuseSetTwo = [
  {
    title: 'Некорректный продукт',
    value: '#НекорректныйПродукт#',
    allowComments: true,
    reject_code_id: 9,
    banUser: false,
  },
  {
    title: 'Отказ по просьбе агента',
    value: '#ОтказПросьбаАгента#',
    allowComments: true,
    reject_code_id: 10,
    banUser: true,
  },
  {
    title: 'Другая',
    value: '#Иное_отказ#',
    allowComments: true,
    reject_code_id: 5,
    banUser: true,
  },
  {
    title: 'ПДН',
    value: '#PTI#',
    allowComments: true,
    reject_code_id: 30,
    banUser: true,
  },
]

export const revisionData = [
  {
    title: 'Видео',
    value: '#Видео#',
    allowComments: true,
    reject_code_id: 20,
  },
  {
    title: 'Фото первой страницы',
    value: '#ФотоПервойСтраницы#',
    allowComments: true,
    reject_code_id: 26,
  },
  {
    title: 'Доп. Доход',
    value: '#Доп. Доход#',
    allowComments: true,
    reject_code_id: 29,
    proposed_other_income: true,
  },
  {
    title: 'Фото регистрации',
    value: '#ФотоРегистрации#',
    allowComments: true,
    reject_code_id: 25,
  },
  {
    title: 'Фото карты',
    value: '#ФотоКарты#',
    allowComments: true,
    reject_code_id: 27,
  },
  {
    title: 'Карта',
    value: '#Карта#',
    allowComments: true,
    reject_code_id: 22,
  },
  {
    title: 'Нет контакта',
    value: '#НетКонтакта#',
    allowComments: true,
    reject_code_id: 11,
  },
]

export const revisionDataSetTwo = [
  ...revisionData,
  {
    title: 'Выгрузка НБКИ',
    value: '#Выгрузка НБКИ',
    allowComments: true,
    reject_code_id: 28,
  },
]

export const REVISION_DATA = {
  [CreditProduct.installment_20_30]: [...revisionData],
  [CreditProduct.installment_16_30k_08_25w_agent]: [...revisionData],
  [CreditProduct.installment_30_40k_055_36w]: [...revisionData],
  [CreditProduct.installment_30_40k_05_36w]: [...revisionData],
  [CreditProduct.installment]: [...revisionData],
  [CreditProduct.installment_9k]: [...revisionData],
  [CreditProduct.installment_2]: [...revisionData],
  [CreditProduct.installment_3]: [...revisionData],
  [CreditProduct.installment_20_30_1]: [...revisionData],
  [CreditProduct.installment_11_19k]: [...revisionData],
  [CreditProduct.installment_30_50k]: [...revisionData],
  [CreditProduct.installment_30_40k_05_36w]: [...revisionData],
  [CreditProduct.installment_30_50k_048_52w]: [...revisionData],
  [CreditProduct.installment_30_100k]: [...revisionData],
  [CreditProduct.installment_30_50k_distant]: [...revisionData],
  [CreditProduct.installment_30_100k_distant]: [...revisionData],
  [CreditProduct.installment_20_30k_distant]: [...revisionData],
  [CreditProduct.installment_30_50k_054_52w]: [...revisionData],
  [CreditProduct.installment_30_50k_054_52w_limit]: [...revisionData],

  [CreditProduct.installment_16_30k_08_25w]: [...revisionData],
  [CreditProduct.installment_31_50k_073_25w]: [...revisionData],
  [CreditProduct.installment_51_70k_068_25w]: [...revisionData],
  [CreditProduct.installment_30_100k_038_52w]: [...revisionData],
  [CreditProduct.installment_30_100k_031_52w]: [...revisionData],

  [CreditProduct.installment_30_100k_053_52w]: [...revisionData],
  [CreditProduct.installment_71_150k_05_78w]: [...revisionData],
  [CreditProduct.bankrupt_30_100k_053_52w]: [...revisionData],

  [CreditProduct.bankrupt_30_100k_055_52w]: [...revisionDataSetTwo],
  [CreditProduct.bankrupt_30_50k_08_24w]: [...revisionDataSetTwo],
  [CreditProduct.bankrupt_30_100k_05_52w]: [...revisionDataSetTwo],
  [CreditProduct.bankrupt_20_30k_08_12w]: [...revisionDataSetTwo],
  [CreditProduct.bankrupt_20_30k_08_12w_agent]: [...revisionDataSetTwo],
  [CreditProduct.bankrupt_30_100k_038_52w]: [...revisionDataSetTwo],
  [CreditProduct.bankrupt_30_100k_031_52w]: [...revisionDataSetTwo],
}

export const REFUSE_DATA = {
  [CreditProduct.installment_20_30]: [...refuseSetOne],
  [CreditProduct.installment_16_30k_08_25w_agent]: [...refuseSetOne],
  [CreditProduct.installment_16_30k_08_25w]: [...refuseSetOne],
  [CreditProduct.installment_31_50k_073_25w]: [...refuseSetOne],
  [CreditProduct.installment_51_70k_068_25w]: [...refuseSetOne],
  [CreditProduct.installment_30_40k_055_36w]: [...refuseSetTwo],
  [CreditProduct.installment_30_40k_05_36w]: [...refuseSetTwo],
  [CreditProduct.installment]: [...refuseSetTwo],
  [CreditProduct.installment_9k]: [...refuseSetTwo],
  [CreditProduct.installment_2]: [...refuseSetTwo],
  [CreditProduct.installment_3]: [...refuseSetTwo],
  [CreditProduct.installment_20_30_1]: [...refuseSetTwo],
  [CreditProduct.installment_11_19k]: [...refuseSetTwo],
  [CreditProduct.installment_30_50k]: [...refuseSetTwo],
  [CreditProduct.installment_30_40k_05_36w]: [...refuseSetTwo],
  [CreditProduct.installment_30_50k_048_52w]: [...refuseSetTwo],
  [CreditProduct.installment_30_100k]: [...refuseSetTwo],
  [CreditProduct.installment_30_100k_038_52w]: [...refuseSetTwo],
  [CreditProduct.installment_30_100k_031_52w]: [...refuseSetTwo],
  [CreditProduct.installment_30_50k_distant]: [...refuseSetTwo],
  [CreditProduct.installment_30_100k_distant]: [...refuseSetTwo],
  [CreditProduct.installment_20_30k_distant]: [...refuseSetTwo],
  [CreditProduct.installment_30_50k_054_52w]: [...refuseSetTwo],
  [CreditProduct.installment_30_50k_054_52w_limit]: [...refuseSetTwo],
  [CreditProduct.bankrupt_30_100k_055_52w]: [...refuseSetTwo],
  [CreditProduct.bankrupt_30_50k_08_24w]: [...refuseSetTwo],
  [CreditProduct.bankrupt_30_100k_05_52w]: [...refuseSetTwo],
  [CreditProduct.bankrupt_20_30k_08_12w]: [...refuseSetTwo],
  [CreditProduct.bankrupt_20_30k_08_12w_agent]: [...refuseSetTwo],
  [CreditProduct.bankrupt_30_100k_038_52w]: [...refuseSetTwo],
  [CreditProduct.bankrupt_30_100k_031_52w]: [...refuseSetTwo],
  [CreditProduct.installment_30_100k_053_52w]: [...refuseSetTwo],
  [CreditProduct.installment_71_150k_05_78w]: [...refuseSetTwo],
  [CreditProduct.bankrupt_30_100k_053_52w]: [...refuseSetTwo],
}

export const SCORING_DATA = {
  [CreditProduct.installment_16_30k_08_25w]: {
    '90-94': 16000,
    '95-99': 16000,
    '100-104': 17000,
    '105-109': 17000,
    '110-114': 17000,
    '115-119': 18000,
    '120-124': 19000,
    '125-129': 20000,
    '130-134': 21000,
    '135-139': 22000,
    '140-144': 23000,
    '145-149': 24000,
    '150-154': 25000,
    '155-159': 26000,
    '160-164': 27000,
    '165-169': 28000,
    '170-174': 29000,
    '175+++': 30000,
  },
  [CreditProduct.installment_16_30k_08_25w_agent]: {
    '60-64': 18000,
    '65-69': 19000,
    '70-74': 20000,
    '75-79': 21000,
    '80-84': 22000,
    '85-89': 23000,
    '90-94': 24000,
    '95-99': 25000,
    '100-104': 26000,
    '105-109': 27000,
    '110-114': 28000,
    '115-119': 29000,
    '120+++': 30000,
  },
}

export function getAmountForScoring(
  scoring: number,
  productData: { [key: string]: number }
): number {
  let resultAmount = 0
  for (const range in productData) {
    if (range.includes('+++')) {
      const baseScore = parseInt(range.split('+++')[0], 10)
      if (scoring >= baseScore) {
        resultAmount = productData[range]
        break
      }
    } else {
      const [low, high] = range.split('-').map(Number)
      if (scoring >= low && scoring <= high) {
        resultAmount = productData[range]
        break
      }
    }
  }
  return resultAmount
}
