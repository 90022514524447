/* eslint-disable arrow-body-style */
/* eslint-disable indent */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */
import { rolesType } from 'constants/rtoles'
import { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'

import { AccessWrapper, LoanStatuses, normalizeValues } from 'helpers'
import { ToastTypes } from 'lib/toast/Toast'
import { GlobalState } from 'index'
import { deleteVideoVerification, fetchVideoVerification } from 'features/loan/extra'
import { addToast } from 'features/toast/toastSlice'
import { IVideoVerificationData } from 'features/loan/types'
import { Button, ModalConfirm, Module } from 'components'
import { StyledVideoWrapper, StyledVideoItem, StyledDescriptionVideo } from './Modules.styles'
import { PermissionContext } from 'app/contexts'

const moduleId = 'videoConfirmation'

interface IModal {
  show: boolean
  title: string
  action?: any
  style: string
}

export const VideoConfirmation: any = ({
  openedModules,
  onToggleModule,
  customerId,
  claimsResolve,
  role,
  loanStatus,
}): JSX.Element => {
  const dispatch = useDispatch()
  const isOpen = openedModules.includes(moduleId)
  const { permissions } = useContext(PermissionContext)

  const {
    videoVerification: { isFetching, data },
  } = useSelector((state: GlobalState) => state.singleLoan)

  const [videoData, setVideoData] = useState<IVideoVerificationData[]>(null)
  const [showModal, setShowModal] = useState<IModal>({
    show: false,
    title: '',
    action: null,
    style: '',
  })

  useEffect(() => {
    if (data.length > 0) setVideoData(data)
    else setVideoData(null)
    return () => setVideoData(null)
  }, [data])

  const handleExpand = (): void => onToggleModule(moduleId)

  const handleCloseModal = (): void =>
    setShowModal({ show: false, title: '', action: null, style: '' })

  const handleRemoveVideo = ({ id, customer_id }): void => {
    const action = (): void => {
      dispatch(
        deleteVideoVerification({
          video_id: id,
          onSuccess: () => {
            batch(() => {
              dispatch(fetchVideoVerification({ customer_id }))
              dispatch(
                addToast({
                  type: ToastTypes.success,
                  title: 'Успех',
                  description: 'Видео успешно удалено',
                })
              )
            })
          },
          onError: () =>
            dispatch(
              addToast({
                type: ToastTypes.danger,
                title: 'Ошибка',
                description: 'Видео не удалено',
              })
            ),
        })
      )
    }
    setShowModal({
      show: true,
      title: ` Удалить видео ?`,
      action,
      style: 'warning',
    })
  }

  return (
    <Module.Wrapper isOpen={isOpen} id={moduleId}>
      <ModalConfirm
        isOpen={showModal.show}
        onRequestClose={handleCloseModal}
        onClose={handleCloseModal}
        title={showModal.title}
        action={showModal.action}
        style={showModal.style}
      />
      <Module.Header onClick={handleExpand}>
        <Module.Actions isOpen={isOpen}>
          <Module.HeadTitle>
            Видео идентификация
            {!data.length ? (
              <span style={{ color: 'gray', opacity: '0.5' }}> / отсутствует</span>
            ) : null}
          </Module.HeadTitle>
          <Module.Arrow isOpen={isOpen} />
        </Module.Actions>
      </Module.Header>
      <Module.ContentWrapper>
        {isFetching ? (
          <div style={{ minHeight: '150px' }}>
            <Module.LoaderBlock />
          </div>
        ) : (
          <StyledVideoWrapper>
            {videoData
              ? videoData.map((video, index) => {
                  return (
                    <StyledVideoItem key={index}>
                      <video controls width='100%'>
                        <source src={video.video_url} />
                      </video>
                      <StyledDescriptionVideo>
                        <p>
                          Код : <b>{video.code}</b>
                        </p>
                        <p>
                          Потраченное время: <b>{video.time_spent}</b>
                        </p>
                        <p>
                          Дата создания: <b>{normalizeValues(video.create_date, 'source_date')}</b>
                        </p>
                      </StyledDescriptionVideo>
                      <br />
                      <br />
                      <AccessWrapper
                        access={[rolesType.agent, rolesType.regionalManager]}
                        condition={
                          permissions.includes(rolesType.regionalManager) ||
                          claimsResolve ||
                          loanStatus === LoanStatuses.on_check
                        }
                      >
                        <Button
                          onClick={() =>
                            handleRemoveVideo({
                              id: video.video_id,
                              customer_id: video.customer_id,
                            })
                          }
                          type='bigDanger'
                        >
                          Удалить видео
                        </Button>
                      </AccessWrapper>
                    </StyledVideoItem>
                  )
                })
              : ''}
          </StyledVideoWrapper>
        )}
      </Module.ContentWrapper>
    </Module.Wrapper>
  )
}
